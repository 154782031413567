
























































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';
import { IOutboundMessage } from '@/types';

export default defineComponent({
  props: {
    mappingType: { type: String, required: true },
  },
  emit: ['deleteInit'],
  setup(props, { emit }) {
    const dialog = ref(false);

    const componentDetails = {
      title: `Delete this ${props.mappingType} message?`,
      message: 'Please confirm if you wish to remove this mapping configuration.',
    };

    const mappingNamespace = `${props.mappingType}Mapping`;

    const isLoading = computed(():
    boolean => Store.getters[`${mappingNamespace}/getLoading`]);

    const outboundMsg = computed((): IOutboundMessage => Store.getters['outboundMapping/getEditedOutboundMessage']);

    const closeDialog = () => {
      dialog.value = false;
    };

    /**
     *  @summary Dispatches action to delete an outbound message & closes dialog
     *  @author Ewa Murjas
     */
    const deleteItem = async () => {
      if (props.mappingType === 'outbound') {
        // @TODO also delete alarms attached to message in future
        await Store.dispatch('outboundMapping/deleteOutboundMessage', outboundMsg.value.id);
      } else if (props.mappingType === 'inbound') {
        await Store.dispatch(`${mappingNamespace}/deleteInboundMessage`);
      } else if (props.mappingType === 'template') {
        // @TODO phase2 delete template full mappings
      }
      emit('deleteInit', props.mappingType);
      closeDialog();
    };

    return {
      dialog,
      isLoading,
      closeDialog,
      deleteItem,
      outboundMsg,
      componentDetails,
    };
  },
});
