




























































































































































































































































































































import {
  ref,
  computed,
  defineComponent,
  onUpdated,
  PropType,
} from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import router from '@/router';
import Store from '@/store';
import {
  IJobTemplate, IMappingListItem, IOutboundAlert, IWorkorderFieldType, IWorkorderFieldTypeStructure,
} from '@/types';
import MappingWizardDialog from './MappingWizardComponents/MappingWizardDialog.vue';
import MappingsDeleteDialog from './MappingsDeleteDialog.vue';

export default defineComponent({
  components: {
    MappingWizardDialog,
    MappingsDeleteDialog,
  },
  props: {
    sectionData: {
      type: Array as PropType<IMappingListItem[]>,
      default: () => [],
    },
    sectionHeaderData: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    mappingType: {
      type: String,
      default: '',
    },
    mappingStepTitle: {
      type: String,
      default: '',
    },
    mappingStepSecondTitle: {
      type: String,
      default: '',
    },
    mappingStepHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    mappingStepSecondHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const mapping = `${props.mappingType}Mapping`;
    const { templateId } = router.app.$route.params;
    const selectedSectionData = ref();
    const workflowMappings = ref([]);
    const mappings = ref([]);
    const tab = null;
    const currJob = ref(null);

    Store.dispatch(`${mapping}/setWorkOrderTemplate`, templateId);

    const outboundMessageFields = ref();
    const outboundAlarms = ref();
    const outboundEndpoint = computed(() => Store.getters['outboundMapping/getEndpoint']);

    const outboundMessages = computed(() => Store.getters['outboundMapping/getOutboundMessages']);
    const outboundLoading = computed(() => Store.getters['outboundMapping/getOutboundMessageLoading']);

    const isLoading = computed(() => Store.getters['templateMapping/getLoading']);
    const mappingsLoading = computed(() => Store.getters['templateMapping/getMappingsLoading']);

    const outboundMessagePreview = computed(() => {
      if (outboundMessageFields.value) {
        const previewData: any = {};

        outboundMessageFields.value.forEach((field: any) => {
          previewData[field.label] = field.emptyValue === 'string' ? '' : null;
        });
        return previewData;
      }
      return null;
    });

    let updated = false;

    // Headers filtering out actions col
    const mappingHeaders = props.mappingStepHeaders.filter(
      (header) => header.value !== 'actions',
    );
    const mappingSecondHeaders = props.mappingStepSecondHeaders.filter(
      (header) => header.value !== 'actions',
    );
    const workflowHeaders = computed(() => {
      let headers = props.sectionHeaderData;
      if (props.mappingType === 'template') {
        headers = headers.filter((h) => h.value !== 'actions');
      }

      return headers;
    });

    const templateData = computed(() => {
      const temps = Store.getters['templateMapping/getTemplateJobs'];
      return temps.map((template: IJobTemplate, index: number) => ({
        ...template,
        jobTemplateName: template.jobTemplateName || `Job Template ${index + 1}`,
      }));
    });

    const alarmHeaders = [
      { text: 'When', align: 'start', value: 'action' },
      { text: 'What', value: 'customFieldName' },
      { text: 'Condition', value: 'criteriaText' },
      { text: 'Value', value: 'value' },
    ];

    const workOrderFields = computed(() => Store.getters['templateMapping/getWorkOrderFields'] as IWorkorderFieldType[]);

    /*
     * FN called on nav link click - sets mapping data as current item
     */
    const openMapping = async (item: any) => {
      Store.commit('templateMapping/setLoading', true);

      if (props.mappingType === 'outbound') {
        outboundMessageFields.value = item.fields;
        outboundAlarms.value = item.alarms;

        Store.dispatch('outboundMapping/setMappings', item.fields);
        Store.dispatch('outboundMapping/setAlerts', outboundAlarms.value);
        if (!Array.isArray(item.options)) Store.dispatch('outboundMapping/setEndpoint', item.options.url);

        Store.dispatch('outboundMapping/setEditedOutboundMessage', item);
      } else if (props.mappingType === 'template') {
        Store.commit('templateMapping/setMappingsLoading', true);
        workflowMappings.value = [];
        mappings.value = [];
        currJob.value = item.id;

        // set job template info for updating / editing
        Store.dispatch('templateMapping/setJobTemplate', item.jobTemplateId);
        Store.commit('templateMapping/setJobTemplateRefId', item.id);

        if (!Store.getters['templateMapping/getWorkflowMappings']?.[templateId]?.[item.id]) {
          await Store.dispatch('templateMapping/loadJobWorkflowMappings', {
            templateId,
            jobId: item.id,
          });
        }
        workflowMappings.value = Store.getters['templateMapping/getWorkflowMappings'][templateId][item.id];

        if (!Store.getters['templateMapping/getMappings']?.[templateId]?.[item.id]) {
          await Store.dispatch('templateMapping/loadJobFieldMappings', {
            templateId,
            jobId: item.id,
          });
        }
        mappings.value = Store.getters['templateMapping/getMappings'][templateId][item.id];

        Store.commit('templateMapping/setMappingsLoading', false);
      }
      Store.commit('templateMapping/setLoading', false);
    };

    /*
     * Default fn to select first element in nav list
     */
    const selectFirstElement = () => {
      const messageList = document.getElementsByClassName('v-treeview-node__selection');
      if (messageList.length) {
        const firstItem = messageList.item(0) as HTMLElement;
        if (firstItem) return firstItem.click();
      }
      return true;
    };

    const getMappingData = computed(() => Store.getters['inboundMapping/getMappings']);

    /*
     * Set template data & mappings list
     */
    const setMappingsData = async () => {
      Store.commit('templateMapping/setLoading', true);

      // TODO change store for whichever current mapping store
      await Store.dispatch(`${mapping}/loadTemplateData`).then(() => {
        if (props.mappingType === 'template') {
          selectFirstElement();
        }
      });
      if (props.mappingType === 'inbound') {
        await Store.dispatch(`${mapping}/setSavedMappings`);
      }
      Store.commit('templateMapping/setLoading', false);
    };

    const loadOutboundMessages = async () => {
      await Store.dispatch('templateMapping/setWorkOrderTemplate', templateId);
      await Store.dispatch('outboundMapping/setWorkOrderTemplate', templateId);
      await Store.dispatch('outboundMapping/loadFieldConditions');
      await Store.dispatch('outboundMapping/loadOutboundMessages');
      await Store.dispatch('templateMapping/loadWorkOrderFields');
    };

    if (props.mappingType === 'outbound') loadOutboundMessages();

    setMappingsData();

    const getFieldType = (fieldId: number) => {
      const fieldData = workOrderFields.value.find((field) => field.value === fieldId) as IWorkorderFieldType;
      return fieldData?.type || '';
    };

    const getSelectOptions = (fieldId: number) => {
      const fieldData = workOrderFields.value.find((field) => field.value === fieldId) as IWorkorderFieldType;
      return fieldData.structure;
    };

    const getSelectItemDescription = (fieldData: IOutboundAlert) => {
      const structure = getSelectOptions(fieldData.customFieldId as number);
      if (structure) {
        const selectItem = structure.find((item) => item.code === fieldData.value) as IWorkorderFieldTypeStructure;
        return selectItem.description;
      }
      return '';
    };

    const onDeleteInit = async (type: 'outbound' | 'template' | 'inbound') => {
      if (type === 'outbound') {
        if (outboundMessages.value.length > 0) {
          outboundMessageFields.value = [];
          selectFirstElement();
        } else {
          router.go(-1);
        }
      } else if (type === 'template') {
        await Store.dispatch('templateMapping/deleteTemplateJob', {
          templateId,
          jobId: currJob.value,
        });
        if (templateData.value.length > 0) {
          selectFirstElement();
        }
      } else if (type === 'inbound') {
        router.go(-1);
      }
    };

    onUpdated(() => {
      if (props.mappingType === 'outbound' && !updated && outboundMessages.value.length) {
        selectFirstElement();
        updated = true;
      }
    });

    /**
     * Triggered when the wizard is closed after editing, reloads outbound messages
     * to make sure only saved ones are shown and sets 'updated' back to false
     * so that the first element in the treeview can be selected on page load
     */
    const reloadData = async () => {
      if (props.mappingType === 'outbound') {
        outboundMessageFields.value = [];
        outboundAlarms.value = [];

        await loadOutboundMessages();
        selectFirstElement();
      } else if (props.mappingType === 'template') {
        setMappingsData();
      }

      updated = false;
    };

    return {
      tab,
      isLoading,
      mappingsLoading,
      openMapping,
      selectedSectionData,
      getMappingData,
      mappings,
      workflowHeaders,
      currJob,
      workflowMappings,
      outboundMessages,
      mappingHeaders,
      mappingSecondHeaders,
      outboundMessageFields,
      outboundLoading,
      reloadData,
      onDeleteInit,
      templateData,
      alarmHeaders,
      outboundAlarms,
      outboundMessagePreview,
      outboundEndpoint,
      getFieldType,
      getSelectItemDescription,
    };
  },
});
