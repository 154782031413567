










import { DataTableHeader } from 'vuetify';
import { defineComponent } from '@vue/composition-api';
import router from '@/router';
import MappingsEditComponent from '@/components/MappingsEditComponent.vue';

export default defineComponent({
  components: {
    MappingsEditComponent,
  },
  setup() {
    const headers = [
      { text: 'Inbound Label', align: 'start', value: 'inboundLabel' },
      { text: 'Template Field', value: 'workOrderFieldName' },
      { text: 'Automatically Update', value: 'inboundOverwrite' },
    ];

    const isPublished = (router.app.$route.params.isPublished === 'true');

    const mappingStepTitle = 'Please make any changes you wish and click save.';
    const mappingStepHeaders: DataTableHeader[] = [
      {
        text: 'Work Order Template Field',
        align: 'start',
        value: 'workOrderFieldName',
      },
      { text: 'Inbound Label', value: 'inboundLabel' },
      { text: 'Automatically Update', value: 'inboundOverwrite' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    return {
      headers,
      mappingStepTitle,
      mappingStepHeaders,
      isPublished,
    };
  },
});
